<template>
    <template v-for="(part, index) in exampleParts" :key="index">
        <strong v-if="part.variable"><Morpheme
            :pronoun="pronoun"
            :morpheme="part.str"
            :counter="counter"
            :prepend="getPrepend(index)"
            :append="getAppend(index)"
        /></strong>
        <Spelling v-else :text="getPrepend(index) + part.str + getAppend(index)" />
    </template>
    <small v-if="link">
        (<nuxt-link :to="`/${pronoun.canonicalName}`"><Spelling escape :text="pronoun.canonicalName" /></nuxt-link>)
    </small>
    <Pronunciation
        v-if="pronunciation && pronoun.pronounceable && example.toPronunciationString(pronoun)"
        :pronunciation="example.toPronunciationString(pronoun) as string"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Example, Pronoun } from '../src/classes.ts';
import type { ExamplePart } from '../src/classes.ts';

const startsWithArabicLetter = /^[\u0621-\u064A]/;
const endsWithArabicLetter = /[\u0621-\u064A]$/;
const zeroWidthJoiner = '\u200d';

export default defineComponent({
    props: {
        example: { required: true, type: Example },
        pronoun: { required: true, type: Pronoun },
        counter: { default: 0, type: Number },
        link: { type: Boolean },
        pronunciation: { type: Boolean },
    },
    computed: {
        exampleParts(): ExamplePart[] {
            return this.example.parts(this.pronoun, this.counter);
        },
    },
    methods: {
        getContent(index: number): string | null {
            if (this.exampleParts[index].variable) {
                return this.pronoun.getMorpheme(this.exampleParts[index].str, this.counter);
            }
            return this.exampleParts[index].str;
        },
        getPrepend(index: number): string {
            if (this.getContent(index)?.match(startsWithArabicLetter) &&
                index > 0 && this.getContent(index - 1)?.match(endsWithArabicLetter)) {
                return zeroWidthJoiner;
            }
            return '';
        },
        getAppend(index: number): string {
            if (this.getContent(index)?.match(endsWithArabicLetter) &&
                index < this.exampleParts.length - 1 && this.getContent(index + 1)?.match(startsWithArabicLetter)) {
                return zeroWidthJoiner;
            }
            return '';
        },
    },
});
</script>
