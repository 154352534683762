<template>
    <span
        :class="['morpheme', 'rounded', isHighlighted ? 'text-bg-primary' : '']"
        @mouseenter="highlightMorphemes(highlightsMorphemes)"
        @mouseleave="highlightMorphemes(new Set())"
    ><Spelling escape :text="prepend + pronoun.getMorpheme(morpheme, counter) + append" /></span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Pronoun } from '../src/classes.ts';
import { intersection } from '../src/sets.ts';
import { useMainStore } from '../store/index.ts';

export default defineComponent({
    props: {
        pronoun: { required: true, type: Pronoun },
        morpheme: { required: true, type: String },
        counter: { default: 0, type: Number },

        prepend: { default: '', type: String },
        append: { default: '', type: String },
        highlightsMorphemes: {
            default(props: { morpheme: string }) {
                if (props.morpheme.startsWith('\'')) {
                    return new Set([props.morpheme.substring(1)]);
                }
                return new Set([props.morpheme]);
            },
            type: Set as PropType<Set<string>>,
        },
    },
    setup() {
        return {
            store: useMainStore(),
        };
    },
    computed: {
        isHighlighted(): boolean {
            return intersection(this.highlightsMorphemes, this.store.highlightedMorphemes).size > 0;
        },
    },
    methods: {
        highlightMorphemes(morphemes: Set<string>): void {
            this.store.highlightMorphemes(morphemes);
        },
    },
});
</script>

<style>
    .morpheme {
        margin: 0 -0.15rem;
        padding: 0 0.15rem;
    }
</style>
